/**
 * ACTIONS
 */

import api from "../api";

/**
 * LOGIN
 */
export const logIn = (user, password) => async (dispatch) => {
  api
    .login(user, password)
    .then(async (res) => {
      /* console.log("AUTH RES", res); */
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        let name = res.data.name;
        let supplier_id = res.data.supplier_id;
        /* let arrayUser = {
          name: name,
          supplier_id: supplier_id,
        };
        localStorage.setItem("auth_user", JSON.stringify(arrayUser)); */
        // OK
        api.reload();
        dispatch(orders_load(supplier_id));
        dispatch(invoices_load(supplier_id));
        dispatch({
          type: "LOGIN",
          payload: {
            login_failed: false,
            logedIn: true,
            name: name,
            supplier_id: supplier_id,
            login_message_error: ``,
          },
        });
      } else {
        // NOT OK
        console.error("LOGIN ERROR", res);
        dispatch({
          type: "LOGIN_FAILED",
          payload: {
            login_failed: true,
            logedIn: false,
            name: null,
            supplier_id: null,
            login_message_error: `Error Login`,
          },
        });
      }
    })
    .catch((err) => {
      // NOT OK
      console.error("LOGIN ERROR", err);
      localStorage.removeItem("token");
      dispatch({
        type: "LOGIN_FAILED",
        payload: {
          login_failed: true,
          logedIn: false,
          name: null,
          supplier_id: null,
          login_message_error: `Error Login ${
            err.response ? err.response.data.messages[0] : err
          }`,
        },
      });
    });
};

/**
 * LOGOUT
 */
export const logOut = () => async (dispatch) => {
  /* localStorage.removeItem("token"); */
  localStorage.clear();
  dispatch({ type: "ORDERS_CLEAN", payload: {} });
  dispatch({ type: "INVOICES_CLEAN", payload: {} });
  dispatch({
    type: "LOGOUT",
    payload: {
      login_failed: false,
      logedIn: false,
      name: null,
      supplier_id: null,
    },
  });
};

/**
 * REFRESH LOGIN
 */
export const refresh = () => async (dispatch) => {
  /* console.log("refresh !!! "); */
  api
    .refresh()
    .then(async (res) => {
      /* console.log("res refresh-> ", res); */
      if (res.data.token) {
        localStorage.setItem("token", res.data.token);
        /* let auth_user = JSON.parse(localStorage.getItem("auth_user")); */
        let name =
          res.data.name; /* !== null ? res.data.name : auth_user.name; */
        let supplier_id =
          res.data
            .supplier_id; /* !== null
            ? res.data.supplier_id
            : auth_user.supplier_id; */
        // OK
        api.reload();
        dispatch(orders_load(supplier_id));
        dispatch(invoices_load(supplier_id));
        /* console.log("supplier_id -> ", supplier_id); */
        dispatch({
          type: "REFRESH",
          payload: {
            login_failed: false,
            logedIn: true,
            name: name,
            supplier_id: supplier_id,
          },
        });
      } else {
        // NOT OK
        console.error("RERESH LOGIN ERROR", res);
        localStorage.removeItem("token");
        dispatch({ type: "ORDERS_CLEAN", payload: {} });
        dispatch({ type: "INVOICES_CLEAN", payload: {} });
        dispatch({
          type: "LOGOUT",
          payload: {
            login_failed: false,
            logedIn: false,
            name: null,
            supplier_id: null,
          },
        });
      }
    })
    .catch((err) => {
      // NOT OK
      console.error("RERESH LOGIN ERROR", err);
      localStorage.removeItem("token");
      dispatch({ type: "ORDERS_CLEAN", payload: {} });
      dispatch({ type: "INVOICES_CLEAN", payload: {} });
      dispatch({
        type: "LOGOUT",
        payload: {
          login_failed: false,
          logedIn: false,
          name: null,
          supplier_id: null,
        },
      });
    });
};

/**
 * ORDERS LOAD
 */
export const orders_load = (supplier_id) => async (dispatch) => {
  /* console.log("supplier_id -> ", supplier_id); */
  api
    .ordenesdecompra(supplier_id, 100, 0)
    .then((orders) => {
      let orders_pending = orders.data.data.length;
      let orders_amount_pending = 0;
      orders.data.data.forEach((o) => {
        orders_amount_pending += Number(o.OrderValue);
      });
      dispatch({
        type: "ORDERS_LOAD",
        payload: {
          orders_loaded: true,
          orders_pending: orders_pending,
          orders_amount_pending: orders_amount_pending,
          orders: orders.data.data,
          orders_no_loading: false,
          orders_message_error: ``,
        },
      });
    })
    .catch((err) => {
      console.error("ORDERS LOAD ERROR", err);
      dispatch({
        type: "ORDERS_LOAD",
        payload: {
          orders_loaded: true,
          orders_no_loading: true,
          orders_message_error: `Error Ordenes ${
            err.response ? err.response.data.messages[0] : err
          }`,
        },
      });
    });
};

/**
 * INVOICES LOAD
 */
export const invoices_load = (supplier_id) => async (dispatch) => {
  api
    .facturas(supplier_id, 100, 0)
    .then((facturas) => {
      let invoices_pending = facturas.data.data.length;
      let invoices_amount_pending = 0;
      facturas.data.data.forEach((f) => {
        invoices_amount_pending += Number(f.TotalAmount);
      });
      dispatch({
        type: "INVOICES_LOAD",
        payload: {
          invoices_loaded: true,
          invoices_pending: invoices_pending,
          invoices_amount_pending: invoices_amount_pending,
          invoices: facturas.data.data,
          invoices_no_loading: false,
          invoices_message_error: ``,
        },
      });
    })
    .catch((err) => {
      console.error("INVOICES LOAD ERROR", err);
      dispatch({
        type: "INVOICES_LOAD",
        payload: {
          invoices_loaded: true,
          invoices_no_loading: true,
          invoices_message_error: `Error Facturas ${
            err.response ? err.response.data.messages[0] : err
          }`,
        },
      });
    });
};

/*
export const po_attachment = (order_no, file) => async dispatch => {
    const res = await api.po_attachment(order_no, file);
    if(res.status === 200)
    {
        // OK
        dispatch({ type: 'PO_ATTACHMENT', payload: { } });
    } else
    {
        // ERROR
    }
}

export const invoice_attachment = (trans_no, file) => async dispatch => {
    const res = await api.invoice_attachment(trans_no, file);
    if(res.status === 200)
    {
        // OK
        dispatch({ type: 'INVOICE_ATTACHMENT', payload: { } });
    } else
    {
        // ERROR
    }
}
*/
