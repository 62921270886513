/**
 * INVOICE REDUCERS
 */

const initialState = {
    invoices_loaded: false,
    invoices_pending: 0,
    invoices_amount_pending: 0,
    invoices: []
}

const orders = (state = initialState, action) => {
    switch (action.type) {
        case 'INVOICES_LOAD':
            return action.payload;
        case 'INVOICES_CLEAN':
            return initialState;
        default:
            return state;
    }
};
export default orders;