/**
 * PORTAL PROVEEDORES API
 */

import axios from "axios";
import { companyHost, companyId } from "../contstants";

const client = axios.create({
  baseURL: companyHost,
  timeout: 1000 * 90,
});

client.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};
client.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded"; //application/multipart/form-data
client.defaults.withCredentials = true;

function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

function reload() {
  client.defaults.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  client.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded"; //application/multipart/form-data
}

const login = (u, p) => {
  // /modules/portal_proveedor/api/v2/login
  // /api/v2/login
  return client.post(
    `/modules/portal_proveedor/api/v2/login`,
    serialize({
      company: companyId,
      user: u,
      pass: p,
    })
  );
};

const refresh = () => {
  // /api/v2/refresh
  return client.get(`/modules/portal_proveedor/api/v2/refresh`);
};

const logout = () => {
  return client.get(`/api/v2/logout`);
};

const facturas = (supplier_id, limit = 100, skip = 0) => {
  // filterType=2&supplier_id=${supplier_id}&TransAfterDate=${TransAfterDate}&TransToDate=${TransToDate}
  return client.get(`/modules/portal_proveedor/api/v2/invoice_pending`, {
    params: {
      supplier_id: supplier_id,
      limit: limit,
      skip: skip,
    },
  });
};

const ordenesdecompra = (supplier_id, limit = 100, skip = 0) => {
  // filterType=2&supplier_id=${supplier_id}&TransAfterDate=${TransAfterDate}&TransToDate=${TransToDate}
  return client.get(`/modules/portal_proveedor/api/v2/po_pending`, {
    params: {
      supplier_id: supplier_id,
      limit: limit,
      skip: skip,
    },
  });
};

const po_attachment = (trans_no, file) => {
  let data = new FormData();
  data.append("trans_no", trans_no);
  data.append("file", file);
  return client.post(`/modules/portal_proveedor/api/v2/po_attachment`, data, {
    headers: {
      "Content-Type": "application/multipart/form-data",
    },
  });
};

const invoice_attachment = (trans_no, file) => {
  let data = new FormData();
  data.append("trans_no", trans_no);
  data.append("file", file);
  return client.post(
    `/modules/portal_proveedor/api/v2/invoice_attachment`,
    data,
    {
      headers: {
        "Content-Type": "application/multipart/form-data",
      },
    }
  );
};

// TODO: METODO APIV2
const direct_invoice_attachment = (file) => {
  let data = new FormData();
  data.append("file", file);
  return client.post(`${window.portal_proveedores.direct_invoice_url}`, data, {
    headers: {
      "Content-Type": "application/multipart/form-data",
    },
  });
};

const po_view = (trans_no) => {
  return client.get(`/modules/portal_proveedor/api/v2/po_view`, {
    params: {
      trans_no: trans_no,
    },
  });
};

const attachments_po = (trans_no) => {
  return client.get(`/modules/portal_proveedor/api/v2/attachments_po`, {
    params: {
      trans_no: trans_no,
    },
  });
};

const attachments_invoice = (trans_no) => {
  return client.get(`/modules/portal_proveedor/api/v2/attachments_invoice`, {
    params: {
      trans_no: trans_no,
    },
  });
};

export default {
  reload,
  login,
  refresh,
  logout,
  facturas,
  ordenesdecompra,
  po_attachment,
  invoice_attachment,
  direct_invoice_attachment,
  po_view,
  attachments_po,
  attachments_invoice,
};
