import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import {
  TotalOrdenes,
  MontoOrdenes,
  TotalFacturas,
  MontoFacturas,
} from "./components";
import { withSnackbar } from "notistack";
import Facturas from "../Facturas";
import OrdenesCompra from "../OrdenesCompra";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Dashboard = (props) => {
  /* console.log("props Dashboard -> ", props); */
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalOrdenes total={props.orders.orders_pending} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <MontoOrdenes monto={props.orders.orders_amount_pending} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalFacturas total={props.invoices.invoices_pending} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <MontoFacturas monto={props.invoices.invoices_amount_pending} />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <OrdenesCompra
            title={`Ultimas 5 Ordenes de Compra Pendientes`}
            limit={5}
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Facturas title={`Ultimas 5 Facturas Pendientes`} limit={5} />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ orders, invoices }) => {
  return { orders, invoices };
};

export default connect(mapStateToProps, null)(withSnackbar(Dashboard));
