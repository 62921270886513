/**
 * ORDER REDUCERS
 */

const initialState = {
    orders_loaded: false,
    orders_pending: 0,
    orders_amount_pending: 0,
    orders: []
}

const orders = (state = initialState, action) => {
    switch (action.type) {
        case 'ORDERS_LOAD':
            return action.payload;
        case 'ORDERS_CLEAN':
            return initialState;
        default:
            return state;
    }
};
export default orders;