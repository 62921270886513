import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  Facturas as FacturasView,
  OrdenesCompra as OrdenesCompraView,
  Settings as SettingsView,
  SignIn as SignInView,
  NotFound as NotFoundView
} from './views';

import { refresh } from './actions';

const Routes = props => {

  useEffect(() => {
    //console.log('ROUTES MOUNT');
    //console.log('AUTH PROPS', props.auth);
    //console.log('TOKEN', localStorage.getItem('token'));
    if(localStorage.getItem('token')!== null && !props.auth.logedIn)
    {
      props.loginRefresh();
    }
  }, [props]);

  if(!props.auth.logedIn) return <SignInView />
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <Redirect
        exact
        from="/sign-in"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={OrdenesCompraView}
        exact
        layout={MainLayout}
        path="/ordenesdecompra"
      />
      <RouteWithLayout
        component={FacturasView}
        exact
        layout={MainLayout}
        path="/facturas"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

const mapStateToProps = ({ auth }) => {
	return { auth };
};

const mapDispatchToProps = dispatch => ({
  loginRefresh: () => dispatch(refresh()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
