import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import {
  CircularProgress,
  Card,
  IconButton,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AttachFile from "@material-ui/icons/AttachFile";
import Dropzone from "react-dropzone";

import { StatusBullet } from "components";
import numeral from "numeral";
//import XMLParser from 'react-xml-parser';
import api from "../../api";
import { orders_load } from "../../actions";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import Popout from "react-popout";

// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    width: "100%",
    fontSize: 9,
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1,
    border: 1,
    width: "98%",
    // flexDirection: 'row',
    height: "auto",
  },
  header1: {
    width: "70%",
  },
  header2: {
    width: "30%",
  },
  boder_bottom: {
    borderBottom: 1,
    borderBottomColor: "#585858",
  },
  section_row: {
    width: "100%",
    flexDirection: "row",
    padding: 3,
  },
  row_space: {
    width: "100%",
    height: 12,
    flexDirection: "row",
  },
  text_header: {
    padding: 2,
  },
  text_table_h: {
    padding: 2,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Helvetica-Bold",
  },
});
let po_subtotal = 0;
let po_total = 0;

// Create Document PDF Component
const MyDocument = (props) => (
  <Document width="100%">
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.row_space} />
        <View style={[styles.section_row, styles.boder_bottom]}>
          <View style={styles.header1}>
            <Text style={styles.text_header}>
              Proveedor:{" "}
              <Text style={{ fontFamily: "Helvetica-Bold" }}>
                {props.po.data.supplier_name}
              </Text>
            </Text>
            <Text style={styles.text_header}>
              Referencia del Proveedor: {props.po.data.supp_ref}
            </Text>
            <Text style={styles.text_header}>
              Dirección de Entrega: {props.po.data.delivery_address}
            </Text>
          </View>
          <View style={styles.header2}>
            <Text
              style={[styles.text_header, { fontFamily: "Helvetica-Bold" }]}
            >
              Orden de Compra #{props.po.data.order_no}
            </Text>
            <Text style={styles.text_header}>
              Fecha Orden: {props.po.data.orig_order_date}
            </Text>
            <Text style={styles.text_header}>
              Ubicación: {props.po.data.Location}
            </Text>
          </View>
        </View>
        <View style={styles.row_space} />
        <View style={styles.row_space} />
        <View style={[styles.section_row, styles.boder_bottom]}>
          <Text>Comentarios: {props.po.data.Comments}</Text>
        </View>
        <View style={styles.row_space} />
        <View style={styles.row_space} />
        <View style={[styles.section_row, styles.boder_bottom]}>
          <Text style={[{ width: "10%" }, styles.text_table_h]}>Codigo</Text>
          <Text style={[{ width: "50%" }, styles.text_table_h]}>
            Descripción
          </Text>
          <Text style={[{ width: "10%" }, styles.text_table_h]}>Cantidad</Text>
          <Text style={[{ width: "15%" }, styles.text_table_h]}>Precio</Text>
          <Text style={[{ width: "15%" }, styles.text_table_h]}>Total</Text>
        </View>
        {props.po.data.line_items.map((item, index) => {
          let po_importe = Number(item.price) * Number(item.quantity);
          po_subtotal += parseFloat(po_importe);
          po_total += parseFloat(po_importe);
          return (
            <View
              key={index}
              style={[
                styles.section_row,
                { backgroundColor: "#F2F2F2", borderBottom: 0.5 },
              ]}
            >
              <Text style={{ width: "10%" }}>{item.stock_id}</Text>
              <Text style={{ width: "50%" }}>{item.item_description}</Text>
              <Text style={{ width: "10%", textAlign: "right" }}>
                {item.quantity}
              </Text>
              <Text style={{ width: "15%", textAlign: "right" }}>
                $ {numeral(item.price).format("0,000.00")}
              </Text>
              <Text style={{ width: "15%", textAlign: "right" }}>
                $ {numeral(po_importe).format("0,000.00")}
              </Text>
            </View>
          );
        })}
        <View style={styles.section_row}>
          <Text style={{ width: "70%" }}></Text>
          <Text style={{ width: "15%" }}>SubTotal:</Text>
          <Text style={{ width: "15%", textAlign: "right" }}>
            ${" "}
            {numeral(po_subtotal).format("0,000.00") +
              " " +
              props.po.data.curr_code}
          </Text>
        </View>
        {props.po.data.tax_group_array.map((tax, index) => {
          po_total += parseFloat(tax.Value);
          return (
            <View key={index} style={styles.section_row}>
              <Text style={{ width: "70%" }}></Text>
              <Text style={{ width: "15%" }}>{tax.tax_type_name}:</Text>
              <Text style={{ width: "15%", textAlign: "right" }}>
                ${" "}
                {numeral(tax.Value).format("0,000.00") +
                  " " +
                  props.po.data.curr_code}
              </Text>
            </View>
          );
        })}
        <View style={styles.section_row}>
          <Text style={{ width: "70%" }}></Text>
          <Text style={{ width: "15%", fontFamily: "Helvetica-Bold" }}>
            Total:
          </Text>
          <Text
            style={{
              width: "15%",
              fontFamily: "Helvetica-Bold",
              textAlign: "right",
            }}
          >
            ${" "}
            {numeral(po_total).format("0,000.00") +
              " " +
              props.po.data.curr_code}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const statusColors = (orden) => {
  if (orden.OverDue) return "warning";
  else return "success";
};
/* let showError = 0; */
const OrdenesCompra = (props) => {
  const { className } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [renderpdf, setRenderPdf] = useState(null);
  const [showError, setShowError] = useState(true);
  // eslint-disable-next-line
  const [limit, setLimit] = useState(props.limit ? props.limit : 100);
  const { auth, orders, ordersLoad } = props;

  /* console.log("props Ordenes -> ", props); */

  useEffect(() => {
    /* console.log("orders.orders_loaded -> ", orders.orders_loaded); */
    if (!orders.orders_loaded) ordersLoad(auth.supplier_id);
    if (orders.orders_loaded) setLoading(false);
    if (orders.orders_no_loading && showError) {
      props.enqueueSnackbar(orders.orders_message_error, {
        variant: "error",
      });
      setShowError(false);
    }
    // eslint-disable-next-line
  }, [orders, auth, ordersLoad, showError]);

  const onDrop = (acceptedFiles, rejectedFiles, event) => {
    //console.log('EVENT TARGET', event.target.name);
    let total = event.target.name;
    const reader = new FileReader();
    //reader.onabort = () => console.log('file reading was aborted')
    //reader.onerror = () => console.log('file reading has failed')
    reader.onload = (e) => {
      //const xmlStr = reader.result;
      //let xml = new XMLParser().parseFromString(xmlStr);
      var parser = new DOMParser();
      var xml = parser.parseFromString(reader.result, "application/xml");
      var comprobante = xml.getElementsByTagName("cfdi:Comprobante")[0];
      // total: xml.attributes.Total
      // TipoCambio: xml.attributes.TipoCambio
      // Moneda: xml.attributes.Moneda
      console.log(
        `TOTAL ${total} TOTAL XML ${Number(comprobante.getAttribute("Total"))}`
      );
      console.log(
        `Math.ABS ${Math.abs(
          total - Number(comprobante.getAttribute("Total"))
        )}`
      );
      if (comprobante.getAttribute("TipoDeComprobante") !== "I") {
        props.enqueueSnackbar(
          "Error al cargar el archivo, el XML no es de tipo INGRESO",
          { variant: "error" }
        );
      } else if (
        Math.abs(total - Number(comprobante.getAttribute("Total"))) > 1.0 ||
        moment(comprobante.getAttribute("Fecha")) <
          moment().subtract(30, "days")
      ) {
        props.enqueueSnackbar(
          "Error al cargar el archivo, el total no coincide o la fecha es mayor a 30 días.",
          { variant: "error" }
        );
      } else {
        api
          .po_attachment(event.target.id, acceptedFiles[0])
          .then((res) => {
            console.debug("po_attachment result", res);
            props.enqueueSnackbar("Archivo cargado con éxito.", {
              variant: "success",
            });
          })
          .catch((err) => {
            console.error(err);
            props.enqueueSnackbar(
              `Error al cargar el archivo. ${
                err.response ? err.response.data.messages[0] : err
              }.`,
              { variant: "error" }
            );
          });
      }
    };
    reader.readAsText(acceptedFiles[0]);
    //acceptedFiles.forEach(file => reader.readAsText(file));
  };

  const poView = (trans_no) => {
    api
      .po_view(trans_no)
      .then((po) => {
        po_subtotal = 0;
        po_total = 0;
        setRenderPdf(po);
        /*
        ReactDOM.render(
          <PDFViewer style={{ width: '100%', height:'100%' }}>{MyDocument(po)}</PDFViewer>,
          document.getElementById("root")
        );
        */
      })
      .catch((err) => {
        console.error(err);
        props.enqueueSnackbar(
          `Error al consultar la Orden de Compra, favor de intentar mas tarde. ${
            err.response ? err.response.data.messages[0] : err
          }.`,
          { variant: "error" }
        );
      });
  };

  const pdfClose = () => {
    setRenderPdf(null);
  };

  return (
    <Fragment>
      {renderpdf != null && (
        <Popout title="OC" onClosing={() => pdfClose()}>
          <PDFViewer style={{ width: "100%", height: "100%" }}>
            <MyDocument po={renderpdf} />
          </PDFViewer>
        </Popout>
      )}
      <div className={classes.root}>
        <Card className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => {
                  props.ordersLoad(auth.supplier_id);
                  setShowError(true);
                }}
              >
                Recargar
              </Button>
            }
            title={props.title ? props.title : "Ordenes de Compra Pendientes"}
          />
          <Divider />
          <CardContent className={classes.content}>
            {loading ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <PerfectScrollbar>
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Orden No.</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Moneda</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell>Estatus</TableCell>
                        <TableCell>Cargar XML Factura</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.orders_pending <= 0 && !loading && (
                        <TableRow>
                          <TableCell style={{ color: "red" }}>
                            No hay Ordenes Pendientes!
                          </TableCell>
                        </TableRow>
                      )}
                      {orders.orders_pending > 0 &&
                        orders.orders.slice(0, limit).map((orden) => (
                          <TableRow hover key={orden.order_no}>
                            <TableCell>
                              <Button onClick={() => poView(orden.order_no)}>
                                {orden.order_no}
                              </Button>
                            </TableCell>
                            <TableCell>
                              {moment(orden.ord_date).format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell>{orden.curr_code}</TableCell>
                            <TableCell align="right">
                              {numeral(orden.total).format("$0,000.00")}
                            </TableCell>
                            <TableCell>
                              <div className={classes.statusContainer}>
                                <StatusBullet
                                  className={classes.status}
                                  color={statusColors(orden)}
                                  size="sm"
                                />
                              </div>
                            </TableCell>
                            <TableCell>
                              <Dropzone
                                onDrop={onDrop}
                                maxSize={2097152}
                                multiple={false}
                                accept={".xml"}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input
                                        {...getInputProps()}
                                        id={orden.order_no}
                                        name={Number(orden.total)}
                                      />
                                      <IconButton children={<AttachFile />} />
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            )}
          </CardContent>
        </Card>
      </div>
    </Fragment>
  );
};

OrdenesCompra.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number,
};

const mapStateToProps = ({ auth, orders }) => {
  return { auth, orders };
};

const mapDispatchToProps = (dispatch) => ({
  ordersLoad: (supplierId) => dispatch(orders_load(supplierId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(OrdenesCompra));
