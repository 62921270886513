import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import AttachFile from "@material-ui/icons/AttachFile";
//import {useDropzone} from 'react-dropzone'
import Dropzone from "react-dropzone";

import { StatusBullet } from "components";
import numeral from "numeral";
//import XMLParser from 'react-xml-parser';
import api from "../../api";
import { invoices_load } from "../../actions";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const statusColors = (factura) => {
  if (factura.OverDue) return "danger";
  else if (factura.Allocated > 0) return "warning";
  else return "success";
};
/* let showError = 0; */
const Facturas = (props) => {
  const { className } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(true);
  // eslint-disable-next-line
  const [limit, setLimit] = useState(props.limit ? props.limit : 100);
  const { auth, invoices, invoicesLoad } = props;

  useEffect(() => {
    //if(facturas.length<=0 && loading) loadFacturas();
    if (!invoices.invoices_loaded) invoicesLoad(auth.supplier_id);
    if (invoices.invoices_loaded) setLoading(false);
    if (invoices.invoices_no_loading && showError) {
      props.enqueueSnackbar(invoices.invoices_message_error, {
        variant: "error",
      });
      setShowError(false);
    }
    // eslint-disable-next-line
  }, [invoices, auth, invoicesLoad, showError]);

  const onDrop = (acceptedFiles, rejectedFiles, event) => {
    //let total = event.target.name;
    const reader = new FileReader();
    //reader.onabort = () => console.log('file reading was aborted')
    //reader.onerror = () => console.log('file reading has failed')
    reader.onload = (e) => {
      //const xmlStr = reader.result;
      //let xml = new XMLParser().parseFromString(xmlStr);
      var parser = new DOMParser();
      var xml = parser.parseFromString(reader.result, "application/xml");
      var comprobante = xml.getElementsByTagName("cfdi:Comprobante")[0];
      //console.debug('XML', xml.attributes);
      if (comprobante.getAttribute("TipoDeComprobante") !== "E") {
        props.enqueueSnackbar(
          "Error al cargar el archivo, el XML no es de tipo EGRESO",
          { variant: "error" }
        );
      } else if (
        moment(comprobante.getAttribute("Fecha")) <
        moment().subtract(30, "days")
      ) {
        props.enqueueSnackbar(
          "Error al cargar el archivo, la fecha del XML es mayor a 30 días.",
          { variant: "error" }
        );
      } else {
        api
          .invoice_attachment(event.target.id, acceptedFiles[0])
          .then((res) => {
            console.debug("invoice_attachment result", res);
            props.enqueueSnackbar("Archivo cargado con éxito.", {
              variant: "success",
            });
          })
          .catch((err) => {
            console.error(err);
            props.enqueueSnackbar(
              `Error al cargar el archivo, favor de intentar mas tarde. ${
                err.response ? err.response.data.messages[0] : err
              }.`,
              { variant: "error" }
            );
          });
      }
    };
    reader.readAsText(acceptedFiles[0]);
    //acceptedFiles.forEach(file => reader.readAsText(file));
  };

  const onDropDirecta = (acceptedFiles, rejectedFiles, event) => {
    const reader = new FileReader();
    //reader.onabort = () => console.log('file reading was aborted')
    //reader.onerror = () => console.log('file reading has failed')
    reader.onload = (e) => {
      var parser = new DOMParser();
      var xml = parser.parseFromString(reader.result, "application/xml");
      var comprobante = xml.getElementsByTagName("cfdi:Comprobante")[0];
      if (comprobante.getAttribute("TipoDeComprobante") !== "I") {
        props.enqueueSnackbar(
          "Error al cargar el archivo, el XML no es de tipo INGRESO",
          { variant: "error" }
        );
      } else if (
        moment(comprobante.getAttribute("Fecha")) <
        moment().subtract(30, "days")
      ) {
        props.enqueueSnackbar(
          "Error al cargar el archivo, la fecha del XML es mayor a 30 días.",
          { variant: "error" }
        );
      } else {
        api
          .direct_invoice_attachment(acceptedFiles[0])
          .then((res) => {
            console.debug("factura_directa result", res);
            props.enqueueSnackbar("Factura cargado con éxito.", {
              variant: "success",
            });
          })
          .catch((err) => {
            console.error(err);
            props.enqueueSnackbar(
              `Error al cargar el archivo. ${
                err.response ? err.response.data.messages[0] : err
              }.`,
              { variant: "error" }
            );
          });
      }
    };
    reader.readAsText(acceptedFiles[0]);
    //acceptedFiles.forEach(file => reader.readAsText(file));
  };

  //const {getRootProps, getInputProps} = useDropzone({onDrop: onDrop, multiple: false, maxSize: 2097152, accept: '.xml'})

  return (
    <div className={classes.root}>
      <Card className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <Fragment>
              {window.portal_proveedores &&
                window.portal_proveedores.allow_direct_invoices && (
                  <Dropzone
                    onDrop={onDropDirecta}
                    maxSize={2097152}
                    multiple={false}
                    accept={".xml"}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                          >
                            Enviar Factura
                          </Button>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                )}
              &nbsp;
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={() => {
                  props.invoicesLoad(auth.supplier_id);
                  setShowError(true);
                }}
              >
                Recargar
              </Button>
            </Fragment>
          }
          title={props.title ? props.title : "Facturas Pendientes"}
        />
        <Divider />
        <CardContent className={classes.content}>
          {loading ? (
            <center>
              <CircularProgress />
            </center>
          ) : (
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Referencia</TableCell>
                      <TableCell>Fecha</TableCell>
                      <TableCell>Moneda</TableCell>
                      <TableCell align="right">Total</TableCell>
                      <TableCell>Estatus</TableCell>
                      <TableCell>Cargar XML N.C.</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoices.invoices_pending <= 0 && !loading && (
                      <TableRow>
                        <TableCell>No hay Facturas Pendientes!</TableCell>
                      </TableRow>
                    )}
                    {invoices.invoices_pending > 0 &&
                      invoices.invoices.slice(0, limit).map((factura) => (
                        <TableRow hover key={factura.trans_no}>
                          <TableCell>{factura.supp_reference}</TableCell>
                          <TableCell>
                            {moment(factura.tran_date).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell>{factura.curr_code}</TableCell>
                          <TableCell align="right">
                            {numeral(factura.TotalAmount).format("$0,000.00")}
                          </TableCell>
                          <TableCell>
                            <div className={classes.statusContainer}>
                              <StatusBullet
                                className={classes.status}
                                color={statusColors(factura)}
                                size="sm"
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <Dropzone
                              onDrop={onDrop}
                              maxSize={2097152}
                              multiple={false}
                              accept={".xml"}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <input
                                      {...getInputProps()}
                                      id={factura.trans_no}
                                      name={Number(factura.TotalAmount)}
                                    />
                                    <IconButton children={<AttachFile />} />
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

Facturas.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number,
};

const mapStateToProps = ({ auth, invoices }) => {
  return { auth, invoices };
};

const mapDispatchToProps = (dispatch) => ({
  invoicesLoad: (supplierId) => dispatch(invoices_load(supplierId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Facturas));
