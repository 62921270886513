/**
 * REDUCERS
 */
import { combineReducers } from 'redux';
import auth from './auth';
import orders from './orders';
import invoices from './invoices';

const rootReducer = combineReducers({
    auth: auth,
    orders: orders,
    invoices: invoices
});
export default rootReducer;