/**
 * AUTH REDUCERS
 */

const initialState = {
    login_failed: false,
    logedIn: false,
    name: null,
    supplier_id: null
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return action.payload;
        case 'LOGIN_FAILED':
                return { login_failed: true, logedIn: false, ...action.payload };
        case 'LOGOUT':
            return action.payload;
        case 'REFRESH':
            return action.payload;
        default:
            return state;
    }
};

export default auth;